import React, { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";

const AccordionChart = ({ stock, backlog, placedOrder, incoming, weeks, playerRole }) => {

  const [graphColor, setGraphColor] = useState("#ffb366");

  useEffect(() => {
    if (playerRole != null) {
      getGraphColorFromRole(playerRole);
    }
  }, [playerRole]);

  function getIterationLimit(arr, weeks) {
    return arr.length < weeks ? arr.length : weeks;
  }

  function getGraphColorFromRole(playerRole) {
    switch (playerRole) {
      case "Manufacturer":
        setGraphColor("#bbaaf2");
        break;
      case "Distributor":
        setGraphColor("#3f88c5");
        break;
      case "Wholesaler":
        setGraphColor("#82ca9d");
        break;
      case "Retailer":
        setGraphColor("#ffb366");
        break;
    }
  }

  let stockRechartData = [];
  if (stock && stock.length > 0) {
    for (let i = 0; i < getIterationLimit(stock, weeks); i++) {
      stockRechartData.push({ week: i + 1, stock: stock[i] - backlog[i] });
    }
  }

  let placedOrderRechartData = [];
  if (placedOrder && placedOrder.length > 0) {
    for (let i = 0; i < getIterationLimit(placedOrder, weeks); i++) {
      placedOrderRechartData.push({ week: i + 1, order: placedOrder[i] });
    }
  }

  let incomingDeliveryRechartData = [];
  if (incoming && incoming.length > 0) {
    for (let i = 0; i < getIterationLimit(incoming, weeks); i++) {
      incomingDeliveryRechartData.push({ week: i + 1, incomingDelivery: incoming[i] });
    }
  }

  let accumulatedCostRechartData = [];
  if (stock && stock.length > 0) {
    let cost = 0;
    for (let i = 0; i < getIterationLimit(stock, weeks); i++) {
      cost += stock[i] * 5 + backlog[i] * 25;
      accumulatedCostRechartData.push({ week: i + 1, cost });
    }
  }

  return (
    <>
      {stock && (
        <div style={{ maxWidth: 800, margin: "0 auto" }}>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={stockRechartData} margin={{ top: 10, right: 20, left: 20, bottom: 20 }}>
              <XAxis dataKey="week" label={{ value: "Week", position: "insideBottom", offset: -10 }} />
              <YAxis label={{ value: "Stock", angle: -90, position: "left" }} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Line type="monotone" dataKey="stock" stroke={graphColor} strokeWidth={2} dot={false}/>
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}

      {placedOrder && (
        <div style={{ maxWidth: 800, margin: "0 auto" }}>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={placedOrderRechartData} margin={{ top: 10, right: 20, left: 20, bottom: 20 }}>
              <XAxis dataKey="week" label={{ value: "Week", position: "insideBottom", offset: -10 }} />
              <YAxis label={{ value: "Order", angle: -90, position: "left" }} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Line type="monotone" dataKey="order" stroke={graphColor} strokeWidth={2} dot={false}/>
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}

      {incoming && (
        <div style={{ maxWidth: 800, margin: "0 auto" }}>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={incomingDeliveryRechartData} margin={{ top: 10, right: 20, left: 20, bottom: 20 }}>
              <XAxis dataKey="week" label={{ value: "Week", position: "insideBottom", offset: -10 }} />
              <YAxis label={{ value: "Incoming Delivery", angle: -90, position: "left" }} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Line type="monotone" dataKey="incomingDelivery" stroke={graphColor} strokeWidth={2} dot={false}/>
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}

      {stock && (
        <div style={{ maxWidth: 800, margin: "0 auto" }}>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={accumulatedCostRechartData} margin={{ top: 10, right: 20, left: 20, bottom: 20 }}>
              <XAxis dataKey="week" label={{ value: "Week", position: "insideBottom", offset: -10 }} />
              <YAxis label={{ value: "Accumulated cost", angle: -90, position: "left" }} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Line type="monotone" dataKey="cost" stroke={graphColor} strokeWidth={2} dot={false}/>
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default AccordionChart;