import { Grid, Box } from '@mui/material';

const Footer = () => {
    return <Box
    component="footer"
    sx={{
      backgroundColor: '#f5f5f5',
      padding: '2em',
      textAlign: 'left',
    }}
  >
    <div style={{ maxWidth: "35em", margin: "auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div style={{ fontWeight: 'bold', fontSize: '1.2em' }}>Contact Information</div>
          <div>MA-system AB</div>
          <div>Porfyrvägen 14</div>
          <div>SE-224 78 LUND</div>
          <div>Sweden</div>
          <div>
            <a href="mailto:beergame@masystem.com">beergame@masystem.com</a>
          </div>
          <div>
            <a href="https://www.masystem.se/home">masystem.se</a>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div style={{ fontWeight: 'bold', fontSize: '1.2em' }}>About MA-system</div>
          <div>
            The MA-system company group consists of MA-system Consulting and MA-system Utbildning.
            MA-system offers services and products ranging from strategic profitability analysis and Supply Chain
            Control systems to operational improvements of processes and education of staff.
          </div>
        </Grid>
      </Grid>
    </div>
  </Box>
}

export default Footer;