export function handleQuitGame(onNavigate) {

    // Ta bort gameAccess från localStorage
    localStorage.removeItem('gameAccess');
    localStorage.removeItem('userID');
    localStorage.removeItem('gameID');
    localStorage.removeItem('role');
    localStorage.removeItem('gameOver');

    if(onNavigate) {
        onNavigate();
    }
    
    // return setGameAccessState;
}