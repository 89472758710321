import React from "react";
import Avatar from "@mui/material/Avatar";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { calculatePercentageChange } from "../algorithms/calculatePercentageChange";
import { Card } from "@mui/material";

const SubCardLeadTimeTest = ({ production1, production2, production1Display, production2Display, icon, title, subTitle}) => {

  const percentage = calculatePercentageChange(production2?.slice(-2)[0] || 0, production1?.slice(-1)[0] || 0);

    const trendingIcon = (value) => {
        if (value > 0) {
            return <TrendingUpIcon/>;
        } else if (value < 0) {
            return <TrendingDownIcon/>;
        } else {
            return <TrendingFlatIcon/>;
        }
    }
    
  return (
    <>
      <Card className="mainCard subCard" variant="outlined">
        <div>
          <div>
            <div className="w100" style={{marginTop: "1em"}}>
              <b>Production lead time​</b> <br />
            </div>
            <div>
              {icon}
            </div>
          </div>
          <div>

          </div>
          <div>
            <div><Avatar sx={{bgcolor:'#80c6e7', width:30, height:30}}>{trendingIcon(percentage)}</Avatar></div><div><b>{percentage.toFixed(0)}%</b></div>
          </div>
          <div>
            <div className="centerText">
              Step 1<br />
              <b>{production1?.slice(-1)[0] || 0}</b>
            </div>{" "}
            <div className="centerText">
              Step 2<br />
              <b>{production2?.slice(-1)[0] || 0}</b>
            </div>
          </div>
        </div>
        <div></div>
      </Card>
    </>
  );
};

export default SubCardLeadTimeTest;
