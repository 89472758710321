import React, { useRef, useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSnackbar } from "notistack";
import { HourglassDisabled } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { usePageVisibility } from "../hooks/usePageVisibility";
import { statusGameRequest } from "../requests/statusGameRequest";
import Panel from "../components/Panel";
import HourglassEmpty from "@mui/icons-material/HourglassEmpty";
import { useNavigate } from "react-router-dom";


// Sortering till utrendering av roller ---->
const getOrderRank = (role) => {

  switch (role) {
    case 'Manufacturer': return 1;
    case 'Distributor' : return 2;
    case 'Wholesaler' : return 3;
    case 'Retailer' : return 4;
    default: return 5;
  }
}

const allRoles = ["Retailer", "Wholesaler", "Distributor", "Manufacturer"];
// <------
const Game = () => {

  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [value, setValue] = useState(1 + "");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  const prevGameDataRef = useRef(null);

  const [isPollingEnabled, setIsPollingEnabled] = useState(true);
  const isPageVisible = usePageVisibility();
  const timerIdRef = useRef(null);

  const [gameState, setGameState] = useState({});
  const [ableToPlay, setAbleToPlay] = useState(false);

  const cbAbleToPlay = (val) => {
    setAbleToPlay(val);
  };

  // !!! PILLA INTE !!! ---->
  const handleGameData = useCallback((gameData) => {

    if(gameData) {
      setGameState(gameData.status.Game);
    }
  }, [setGameState]);
  // <---------

  useEffect(() => {
    const handleRole = () => {
      const role = localStorage.getItem("role");
  
      switch (role) {
        case 'Retailer':
          setValue(4 + "");
          break;
        case 'Wholesaler':
          setValue(3 + "");
          break;
        case 'Distributor':
          setValue(2 + "");
         break;
        case 'Manufacturer':
          setValue(1 + "");
          break;
        default:
          setValue(5 + "");
     }
    };

    handleRole();
  }, []);

  const player = {
    userId: localStorage.getItem("userId"),
    gameId: localStorage.getItem("gameId"),
    role: localStorage.getItem("role"),
  };

  // https://medium.com/@atulbanwar/efficient-polling-in-react-5f8c51c8fb1a
  useEffect(() => {

    const pollingCallBack = async () => {
      
      try {
        const result = await statusGameRequest(
          player.userId,
          player.gameId
          );

          if (result.success) {
            
            if (result.response.action == "gameComplete") {

              localStorage.setItem('gameOver', "true");
              navigate("/play/gameOver");

            }
            /* 
            fick bort reRendering genom att lägga till prevGameDataRef som den sätter om response skiljer sig prevGameDataRef.
            Använder man en useState och jämnför (ex: [prevGameData, setPrevGameData]) 
            så funkar inte jämnförelsen om den inte är med i useEffect dependency listan (varför!?!??!), 
            om man inkluderar den i dependency listan ger detta en 2x anrop pg.a en reRendering när den ändras.
            */
            if (JSON.stringify(prevGameDataRef.current) !== JSON.stringify(result.response)) {
              
              if (result.response.action !== "waitingForPlays" || result.response.status.ActingPlayer.Play.length > 0 && result.response.status.ActingPlayer.Play.length !== (result.response.status.Game.Round)) {
                setAbleToPlay(false);
              } else {
                setAbleToPlay(true); 
              }
              prevGameDataRef.current = result.response
              handleGameData(result.response);

            } 

          } else {
            
            setIsPollingEnabled(false);
            
            const snack = enqueueSnackbar(result.error, {
              variant: "error", //| error | success | warning | info
              autoHideDuration: 6000, //Millisekunder eller null
              //style: { cursor: "pointer", width:'97vw'}, //Om vi vill ha fullwidth
              style: { cursor: "pointer" },
              SnackbarProps: {
                onClick: () => {
                  closeSnackbar(snack); //Stäng vid klick
                },
              },
            });
            
          }
          
        } catch (error) {
          
          console.error("Error during polling:", error);
          setIsPollingEnabled(false);
          const snack = enqueueSnackbar(error, {
            variant: "error", //| error | success | warning | info
            autoHideDuration: 6000, //Millisekunder eller null
            //style: { cursor: "pointer", width:'97vw'}, //Om vi vill ha fullwidth
            style: { cursor: "pointer" },
            SnackbarProps: {
              onClick: () => {
                closeSnackbar(snack); //Stäng vid klick
              },
            },
          });
        }
        
      };
      
      const startPolling = () => {
        pollingCallBack();
        timerIdRef.current = setInterval(pollingCallBack, 4000);
      };
      
      const stopPolling = () => { 
        clearInterval(timerIdRef.current);
      };
      
      if (isPollingEnabled && isPageVisible) {
        startPolling();
      } else {
        stopPolling();
      }
      
      return () => {
        /* 
        (rengörningsfunktion) -> När komponenten avmonteras från DOM stoppas setInterval,
        samt att den förhindrar dupplikationer av startPolling som i sin tur triggar setIntervall.
        t.ex: om stopPolling inte skulle deklarerats i return hade det resulterat i flera instanser av pollningar 
        när isPageVisible och isPollingEnabled ändrar sitt state: 
        (on(startPolling 1)->off-on(startPolling 2)) 
        */
       stopPolling();
      };
    }, [isPollingEnabled, isPageVisible, handleGameData]);
    
  return (
    <Box sx={{ width: "100%", typography: "body1", marginTop: "50px" }}>
  <TabContext value={value}>
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <TabList
        onChange={handleChange}
        aria-label="Roles"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {allRoles.map((role) => {
          // Kontrollera om det finns en spelare för den här rollen
          const playerData = gameState.Players && gameState.Players[role];
          const orderRank = getOrderRank(role).toString();

          return (
            <Tab
              key={role}
              icon={!playerData 
                ? <HourglassDisabled/> 
                : (Object.keys(gameState.Players).length === 4 && playerData?.Play?.length === (gameState?.Round))
                  ? <HourglassEmpty/>
                  : <CheckBoxIcon/>
                }
              iconPosition="start"
              label={
                <>
                  {role}<div className="tabSubText">{playerData ? playerData.Name.slice(0, 10) : ''}</div>
                </>
              }
              value={orderRank}
              // !!!!! deaktivera vid GodMode !!!!!!
              disabled={value !== orderRank}
            />
          );
        })}
      </TabList>
    </Box>
    {allRoles.map((role) => {
      const playerData = gameState.Players && gameState.Players[role];
      const orderRank = getOrderRank(role).toString();

      return (
        <TabPanel value={orderRank} key={role}>
          <Panel 
            player={playerData || {}} 
            game={gameState || {}}
            ableToPlay={ableToPlay}
            cbAbleToPlay={cbAbleToPlay}
          />
        </TabPanel>
      );
    })}
  </TabContext>
</Box>
  );
};
export default Game;
