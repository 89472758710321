import React from 'react';
import { Card, Grid, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import maLogo from "../assets/ma_logo.webp";
import Footer from './Footer';

const SelectPaymentMethod = () => {
  const navigate = useNavigate();

  const handleTryAgain = () => {
    navigate('/payment-method');
  };

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>

      <Box sx={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ margin: '0 auto', maxWidth: "35em", marginTop: "2em", marginBottom: "2em" }}>
          <Card style={{ padding: "1em" }} variant="outlined">
            <Grid container>
              <Grid item xs={9}>
                <h1>Payment successful!</h1>
              </Grid>
              <Grid item xs={3}>
                <img src={maLogo} alt="My Logo" style={{ width: '80px', height: 'auto' }} />
              </Grid>
            </Grid>

            <p>Thank you for your purchase! Your payment was processed successfully.</p>
            <p>A prepaid invoice will be sent to your email within 5 working days.</p>

            <Grid container style={{marginTop: "1em"}}>
              <Grid item>
              <Button
                onClick={handleGoHome}
                variant="outlined"
                style={{marginRight: "1em"}}
              >
                Go home
              </Button>
              </Grid>
            </Grid>
          </Card>
        </div>
      </Box>
      <Footer />
    </Box>
  );
};

export default SelectPaymentMethod;
