import React from "react";
import {
  XYPlot,
  VerticalBarSeries,
  LineSeries,
  XAxis,
  YAxis,
  ChartLabel,
} from "react-vis";
import "../../node_modules/react-vis/dist/style.css";
import Avatar from "@mui/material/Avatar";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { calculatePercentageChange } from "../algorithms/calculatePercentageChange";
import TextAnimate from "../utils/TextAnimate";
import InfoTip from "../utils/InfoTip";
import { Card } from "@mui/material";

const MainCardTest = ({ subTitle, title, week, data, chartType }) => {
  const percentage = calculatePercentageChange(
    data?.slice(-2)[0] || 0,
    data?.slice(-1)[0] || 0
  );

  const trendingIcon = (value) => {
    if (value > 0) {
      return <TrendingUpIcon />;
    } else if (value < 0) {
      return <TrendingDownIcon />;
    } else {
      return <TrendingFlatIcon />;
    }
  };

  const setArray = (data) => {
    const initData = new Array(5).fill(0);

    const combinedData = [...initData, ...data].slice(-5);

    return combinedData.reverse().map((yValue, index) => ({
      x: index + 1,
      y: yValue,
    }));
  };

  const trendData = setArray(data || []);

  const maxValue = Math.max(...trendData.map((obj) => obj.y));

  return (
    <>
      <Card className="mainCard" variant="outlined">
        <div>
          <div>
            <div className="w100">
              <b style={{paddingRight: "0.2em"}}>Customer Order</b>
              <InfoTip subject={"incoming"} />
              <br />
              <br />
            </div>
          </div>
          <div>
            <TextAnimate direction={"left"}>
              <h2>{data?.slice(-1)[0] || 0}</h2>
            </TextAnimate>
          </div>
          <div>
            <div>
              <Avatar sx={{ bgcolor: "#80c6e7", width: 30, height: 30 }}>
                {trendingIcon(percentage)}
              </Avatar>
            </div>
            <div>
              <b>{percentage.toFixed(0)}%</b>
            </div>
          </div>
        </div>
        <div className="plot">
          <XYPlot
            yDomain={[0, maxValue < 4 ? 4 : maxValue]}
            height={100}
            width={100}
            color="#80c6e7"
            stroke="#80c6e7"
            margin={{ left: 23, right: 10, top: 5, bottom: 5 }}
          >
            {chartType === "bar" ? (
              <VerticalBarSeries data={trendData} barWidth={0.7} />
            ) : (
              <LineSeries data={trendData} />
            )}
            <XAxis style={{ ticks: { fontSize: "10px" } }} />
            <YAxis
              style={{ ticks: { fontSize: "8px" } }}
            />
            <ChartLabel
              includeMargin={false}
              xPercent={0.025}
              yPercent={1.01}
            />
            <ChartLabel
              includeMargin={false}
              xPercent={0.06}
              yPercent={0.06}
              style={{
                transform: "rotate(-90)",
                textAnchor: "end",
              }}
            />
          </XYPlot>
        </div>
      </Card>
    </>
  );
};

export default MainCardTest;
