import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionChart from "./charts/AccordionChart";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SummaryCard = ({ player, weeks }) => {
  return (
    <div className="summaryCard">
      
      <Accordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>{player.Role}</div>
        </AccordionSummary>
        <AccordionDetails>
        <AccordionChart
        stock={player.Stock}
        backlog={player.Backlog}
        placedOrder={player.Play}
        incoming={player.Incoming}
        weeks={weeks}
        playerRole={player.Role}
      />
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
};

export default SummaryCard;
