// routes
import Router from './routes';
import {SnackbarProvider} from 'notistack'

const App = () => {

  return (
    <SnackbarProvider>
      <Router />
    </SnackbarProvider>
  );
};
export default App;
