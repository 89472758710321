import React, { useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Clear, Replay } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { createGameRequest } from "../requests/createGameRequest";
import { useSnackbar } from "notistack";
import { getAvailableGamesRequest } from "../requests/getAvailableGamesRequest";
import { handleQuitGame } from "../utils/handleQuitGame";
import { joinGameRequest } from "../requests/joinGameRequest";
import { statusGameRequest } from "../requests/statusGameRequest";
import Grid from '@mui/material/Grid';
import { isMobile } from 'react-device-detect';
import bgPic from "../assets/grain_field.webp";
import {
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Switch,
} from "@mui/material";
import InfoTip from "../utils/InfoTip";
import maLogo from "../assets/ma_logo.webp";
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';

const addBotMenuItemStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
};

const initialRoles = [
  {
    id: 1,
    role: "Retailer",
    available: true,
  },
  {
    id: 2,
    role: "Wholesaler",
    available: true,
  },
  {
    id: 3,
    role: "Distributor",
    available: true,
  },
  {
    id: 4,
    role: "Manufacturer",
    available: true,
  },
];

const CreateJoinGame = () => {
  const navigate = useNavigate();
  const [openNew, setOpenNew] = useState(false);
  const [openJoin, setOpenJoin] = useState(false);
  const [openRole, setOpenRole] = useState(false);
  const [role, setRole] = useState("");
  const [alias, setAlias] = useState("");
  const [availableGames, setAvailableGames] = useState([]);
  const [weeks, setWeeks] = useState(50);
  const [enableGhostPlayers, setEnableGhostPlayers] = useState(false);
  const [ghostPlayers, setGhostPlayers] = useState(null);
  const [gameName, setGameName] = useState("");
  const [gameId, setGameId] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [gameStarter, setGameStarter] = useState(false);
  const [availableRoles, setAvailableRoles] = useState(initialRoles);

  let isRequesting = false;
  // philip -->
  // används för att kolla om där är ett game som man har vart inne i tidigare eller skapats.
  // detta bör kollas mot fler parametrar såsom userId/gameId som också lagras i localStorage
  const [gameAccess, setGameAccess] = useState(
    localStorage.getItem("gameAccess") === "true"
  );
  // <---

  const resetGameIdAndAvailRoles = () => {
    setAvailableRoles(initialRoles);
    setGameId("");
  };

  const fetchGames = async () => {
    const games = await getAvailableGamesRequest();
    if (typeof games[0] === "object") {
      const sortedGames = games.sort(
        (a, b) => new Date(b.Created) - new Date(a.Created)
      );
      setAvailableGames(sortedGames);
    } else {
      setAvailableGames(games);
    }
  };

  const fetchAvailRoles = async () => {
    if (!gameStarter) {
      const result = await statusGameRequest(null, gameId);

      if (result.success) {
        const takenRoles = Object.values(
          result.response.status?.Game?.Players
        ).map((role) => role.Role);

        const updateAvailRoles = availableRoles.map((role) => {
          if (takenRoles.includes(role.role)) {
            return { ...role, available: false };
          } else {
            return role;
          }
        });
        setAvailableRoles(updateAvailRoles);
      } else {
        const snack = enqueueSnackbar(result.error, {
          variant: "error", //| error | success | warning | info
          autoHideDuration: 6000, //Millisekunder eller null
          style: { cursor: "pointer" },
          SnackbarProps: {
            onClick: () => {
              closeSnackbar(snack); //Stäng vid klick
            },
          },
        });
        console.log(result.errorMessage);
      }
    } else return;
  };

  useEffect(() => {
    fetchGames();
  }, []);

  const handleClose = () => {
    setOpenJoin(false);
    setOpenNew(false);
    setOpenRole(false);
    setAvailableRoles(initialRoles);
  };
  const startGame = () => {
    setNewGhostPlayers();
    setOpenJoin(false);
    setOpenNew(false);
    setOpenRole(false);
    setGameStarter(true);
    setOpenRole(true);
  };
  const joinGame = () => {

    handleClose();
    setGameStarter(false);
    setOpenRole(true);

    fetchAvailRoles();
  };

  const handleRoleChange = (id) => {
    const updatedRoles = availableRoles.map((role) => {
      if (role.id === id) {
        return { ...role, available: !role.available };
      }
      return role;
    });

    setAvailableRoles(updatedRoles);
  };

  const disabledCondition =
    availableRoles.filter((role) => !role.available).length >= 3;

  const setNewGhostPlayers = () => {
    const newGhostPlayers = availableRoles
      .filter((role) => !role.available)
      .map((role) => role.role);

    setGhostPlayers(newGhostPlayers);
  };

  const getRoleAndPlay = async () => {
    if (isRequesting) return;

    isRequesting = true;

    try {
      const result = gameStarter
        ? await createGameRequest(role, gameName, weeks, alias, ghostPlayers)
        : await joinGameRequest(role, gameId, alias, gameName);

      if (result.success) {
        localStorage.setItem("gameAccess", "true"); // låser upp ProtectedRoutes om success: true

        // navigate("/playTest");
        navigate("/play");
        const snack = enqueueSnackbar("Game on!", {
          variant: "success", //| error | success | warning | info
          autoHideDuration: 6000, //Millisekunder eller null
          style: { cursor: "pointer" },
          SnackbarProps: {
            onClick: () => {
              closeSnackbar(snack); //Stäng vid klick
            },
          },
        });
      } else {
        const snack = enqueueSnackbar(result.error, {
          variant: "error", //| error | success | warning | info
          autoHideDuration: 6000, //Millisekunder eller null
          style: { cursor: "pointer" },
          SnackbarProps: {
            onClick: () => {
              closeSnackbar(snack); //Stäng vid klick
            },
          },
        });
        console.log(result.errorMessage);
      }
    } catch (error) {
      console.log("An error occurred: ", error);
    } finally {
      isRequesting = false;
    }
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          backgroundImage: `url(${bgPic})`,
          backgroundSize: "cover",
          backgroundPosition: "right bottom",
          height: "100vh",
          width: "100%",
        }}
      >
        <Dialog fullWidth maxWidth="sm" open={openNew} onClose={handleClose}>
          <DialogTitle>Start a new game</DialogTitle>

          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Game name"
              type="text"
              fullWidth
              variant="outlined"
              value={gameName}
              onChange={(event) => setGameName(event.target.value)}
            />
            <hr />
            <List sx={{ bgcolor: "background.paper" }}>
              <Divider sx={{ marginBottom: 2 }} textAlign="left">
                <InputLabel id="gp">
                  Ghost players
                  {enableGhostPlayers ? <InfoTip subject={"botInfo"} /> : null}
                  <Switch
                    sx={{ marginLeft: 1 }}
                    checked={enableGhostPlayers}
                    onChange={() => {
                      setEnableGhostPlayers((preValue) => !preValue),
                        setAvailableRoles(initialRoles);
                    }}
                  />
                </InputLabel>
                
              </Divider>

              {enableGhostPlayers &&
                availableRoles.map((role) => {
                  const labelId = `checkbox-list-label-${role.role}`;
                  return (
                    <ListItem style={addBotMenuItemStyle} key={role.id}>
                      <ListItemButton
                        disabled={disabledCondition && role.available}
                        role={undefined}
                        onClick={() => handleRoleChange(role.id)}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            disableRipple
                            checked={!role.available}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={role.role} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>

            <Accordion
              style={{ marginTop: 20, borderRadius: '5px' }}
              variant="outlined"
              sx={{
                '&:before': {
                  display: 'none',
                },
                '&.Mui-expanded': {
                  borderRadius: '10px',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  '&.Mui-expanded': {
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                  },
                }}
              >
                <div>Advanced features</div>
              </AccordionSummary>
              <AccordionDetails>
                <InputLabel id="ro">
                  Game Duration (number of rounds)
                </InputLabel>
                <Select
                  style={{ marginBottom: 20 }}
                  labelId="ro"
                  id="ro"
                  label="Rounds"
                  value={weeks}
                  onChange={(event) => {
                    setWeeks(event.target.value);
                  }}
                  fullWidth
                >
                  <MenuItem value={weeks}>Automatic</MenuItem>
                  <MenuItem value={50}>A full year</MenuItem>
                  <MenuItem value={35}>35 weeks</MenuItem>
                  <MenuItem value={20}>20 weeks</MenuItem>
                </Select>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={startGame}>Start a new game</Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth maxWidth="sm" open={openJoin} onClose={handleClose}>
          <DialogTitle sx={{ textAlign: 'left' }}>Available games</DialogTitle>
          <DialogContent>
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Autocomplete
                disablePortal={false} // allows autocomplete to expand outside the dialog
                id="comboBox"
                options={availableGames}
                getOptionLabel={(option) => (option.Name !== "" ? option.Name : option.ID)}
                style={{ marginTop: "1em" }}
                fullWidth
                renderOption={(props, game) => (
                  <li {...props} key={game.ID}>
                    {game.Name !== "" ? game.Name : game.ID}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Find your game" />}
                onChange={(event, game) => {
                  if (game) {
                    setGameId(game.ID);
                    setGameName(game.Name);
                  }
                }}
                onOpen={fetchGames}
              />

              {/* Buttons aligned to the left under the select input */}
              <Box sx={{ mt: 2 }}>
                <Button onClick={handleClose} sx={{ mr: 1 }}>Cancel</Button>
                <Button onClick={joinGame}>Join game</Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog fullWidth maxWidth="sm" open={openRole} onClose={handleClose}>
          <DialogTitle>Select your role</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Select any available role to join the game
            </DialogContentText>
            <FormControl fullWidth>
              <InputLabel id="dd">Role</InputLabel>
              <Select
                onOpen={fetchAvailRoles}
                labelId="dd"
                id="dds"
                value={role || ""}
                label="Role"
                onChange={(event) => setRole(event.target.value)}
              >
                {availableRoles.map((row) => (
                  <MenuItem
                    disabled={!row.available}
                    // philip -->
                    value={row.role} // ändra till .role för att lira med api.
                    // <--

                    key={row.id}
                  >
                    {row.role}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Type in your name or alias"
                type="text"
                fullWidth
                variant="standard"
                value={alias}
                onChange={(event) => setAlias(event.target.value)}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={getRoleAndPlay} disabled={isRequesting}>
              {isRequesting ? "Loading..." : "Let's go!"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={!openJoin && !openNew && !openRole}
          hideBackdrop
        >
          <div style={{ minHeight: isMobile ? 500 : 400 }} className="loginWrapper">
            <div className="centerText">
              <h1 className="colorPrimary">Play the Beer Game</h1>
              <h3>Learn supply chain principles through a visual simulation</h3>
            </div>
            <div className="stdFlexColCenter300">
              {gameAccess ? (
                <>
                  <LoadingButton
                    loading={false}
                    loadingPosition="start"
                    startIcon={<Replay />}
                    variant="outlined"
                    onClick={() =>
                      // navigate("/playTest")
                      navigate("/play")
                    }
                  >
                    Resume Game
                  </LoadingButton>

                  <LoadingButton
                    loading={false}
                    loadingPosition="start"
                    startIcon={<Clear />}
                    variant="outlined"
                    onClick={() => (handleQuitGame(setGameAccess), navigate("/"))}
                  >
                    Quit Game
                  </LoadingButton>
                </>
              ) : (
                <>
                  <LoadingButton
                    loading={false}
                    loadingPosition="start"
                    startIcon={<AddBoxIcon />}
                    variant="outlined"
                    onClick={() => {
                      resetGameIdAndAvailRoles();
                      setOpenNew(true);
                    }}
                  >
                    New game
                  </LoadingButton>

                  <LoadingButton
                    loading={false}
                    loadingPosition="start"
                    startIcon={<PersonAddIcon />}
                    variant="outlined"
                    onClick={() => setOpenJoin(true)}
                  >
                    Join game
                  </LoadingButton>

                  <Grid container style={{ marginTop: "2em" }} alignItems="center">
                    <Grid item xs={4} style={{ textAlign: 'center', marginBottom: '1em' }}>
                      <a href="https://masystem.se/home">
                        <img src={maLogo} alt="Logo" style={{ width: "70%", height: "auto" }} />
                      </a>
                    </Grid>
                    <Grid item xs={8}>
                      <p>
                        This application is available free of charge for universities and non-profit organizations. For commercial organizations, please <Link to="/payment-method">click here</Link>.
                      </p>
                    </Grid>
                  </Grid>

                </>
              )}
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};
export default CreateJoinGame;
