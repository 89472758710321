import { Button, Link } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { handleQuitGame } from "../utils/handleQuitGame";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';

import {
  Typography,
  Box,
  Modal,
} from "@mui/material";
import { useEffect, useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "8px",
  p: 4,
};

const Comp = () => {
  let gameOver = localStorage.getItem('gameOver') === "true";
  let gameAccess = localStorage.getItem('gameAccess') === "true";
  let gameName = localStorage.getItem('gameName');
  let gameId = localStorage.getItem('gameId');
  
  const navigate = useNavigate();

  const quitGame = () => {
    handleQuitGame(() => navigate("/"));
  };

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
  }, [gameAccess])
  return <>
    <div className="topRow" hidden={!gameAccess}>
      <h2>{gameAccess || gameOver ? (gameName && gameName !== "" ? gameName.slice(0, 15) : gameId) : null}</h2>
      { gameOver ? (
        <Link color='inherit' underline='none' href="https://www.masystem.se/Home">
          <Button
          disableRipple
          className="quitGameButton"
          onClick={quitGame} 
          startIcon={<KeyboardReturnOutlinedIcon />}
          style={{position: 'absolute', top: 8, right: 24, padding: 8}}
        >
          return to ma-system
        </Button>
        </Link>
        ) : gameAccess ? (
        <Button
          disableRipple  
          className="quitGameButton"
          onClick={() => setOpenModal((prev) => !prev)} 
          startIcon={<HighlightOffIcon />}
          style={{position: 'absolute', top: 8, right: 24, padding: 8}}
        >
          quit game 
        </Button>
        ) : null
      }
    </div>
    <Outlet/>
    <Modal
        open={openModal}
        onClose={() => setOpenModal((prev) => !prev)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"  
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button
            onClick={() => {setOpenModal((prev) => !prev), localStorage.setItem('gameOver', "true"), navigate("/play/gameOver")}} 
            >yes</Button>
            <Button
            onClick={() => setOpenModal((prev) => !prev)} 
            >cancel</Button>
          </Typography>
        </Box>
      </Modal>
  </>;
};
export default Comp;
