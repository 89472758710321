import React from "react";
import { RadialChart } from "react-vis";
import { calculateCosts } from "../algorithms/claculateCosts";
import TextAnimate from "../utils/TextAnimate";
import InfoTip from "../utils/InfoTip";
import { Card } from "@mui/material";

const ProgressCard = ({ game, player, week }) => {
  const getGameTotalStockAndBacklog = (variant) => {
    const result = {
      Stock: 0,
      Backlog: 0,
    };

    if (!game || !game.Players) {
      return result;
    }

    Object.values(game.Players).forEach((playerItem) => {
      if (playerItem.Role === player.Role) {
        return;
      }

      if (variant === "weekly") {
        result.Stock += playerItem.Stock.slice(-1)[0];
        result.Backlog += playerItem.Backlog.slice(-1)[0];
      } else {
        result.Stock += playerItem.Stock.reduce(
          (acc, current) => acc + current,
          0
        );
        result.Backlog += playerItem.Backlog.reduce(
          (acc, current) => acc + current,
          0
        );
      }
    });

    return result;
  };

  const playerWeeklyCosts =
    (player.Stock?.slice(-1)[0] || 0) * 5 +
    (player.Backlog?.slice(-1)[0] || 0) * 25;

  const playerTotalCosts = calculateCosts(player.Stock, player.Backlog);

  const gameTotalCosts =
    getGameTotalStockAndBacklog().Backlog * 25 +
    getGameTotalStockAndBacklog().Stock * 5;
  const gameWeeklyCosts =
    getGameTotalStockAndBacklog("weekly").Backlog * 25 +
    getGameTotalStockAndBacklog("weekly").Stock * 5;

  const playerTotalAverage = () => {
    return game.Round > 0
      ? playerTotalCosts / (game.Round + 1)
      : playerTotalCosts / 1;
  };

  const costColor = (costs) => {
    if (costs <= 75) {
      return "#43a047";
    } else if (costs >= 125) {
      return "#d32f2f";
    } else {
      return "#ff9800";
    }
  };

  const pieChartWeeklyData = [
    {
      angle: gameWeeklyCosts,
      radius: 5,
      label: "Production chain total",
      color: "#80c6e7",
    },
    {
      angle: playerWeeklyCosts,
      radius: 6,
      label: "your costs",
      color: costColor(playerWeeklyCosts),
    },
  ];
  const pieChartTotalData = [
    {
      angle: gameTotalCosts,
      radius: 5,
      label: "Production chain total",
      color: "#80c6e7",
    },
    {
      angle: playerTotalCosts,
      radius: 6,
      label: "your costs",
      color: costColor(playerTotalAverage()),
    },
  ];

  return (
    <>
      <Card className="progressCard" variant="outlined" style={{maxHeight: "10.5em", minHeight: "10.5em"}}>
        <div style={{ display: "flex", alignItems: "center", gap: "4px", marginTop: "0.7em" }}>
          {" "}
          <b>Week:</b>
          <TextAnimate direction={"left"}>
            <b>{week}</b>
          </TextAnimate>
          <InfoTip subject={"progress"} />
        </div>
        <div className="pieCharts">
          <div className="pieChartUnit">
            <div className="PieStackWrapper">
              <RadialChart
                innerRadius={23}
                radius={26}
                colorType="literal"
                data={pieChartWeeklyData}
                width={85}
                height={85}
              />
              <b
                className="pieChartShadow"
                style={{
                  fontSize: `${
                    playerWeeklyCosts.toString().length > 3 ? 12 : 16
                  }px`,
                }}
              >
                ${playerWeeklyCosts || 0}
              </b>
            </div>
            <i>(Current week costs)</i>
          </div>
          <div className="pieChartUnit">
            <div className="PieStackWrapper">
              <RadialChart
                innerRadius={23}
                radius={26}
                colorType="literal"
                data={pieChartTotalData}
                width={85}
                height={85}
              />
              <b
                className="pieChartShadow"
                style={{
                  fontSize: `${
                    playerTotalCosts && playerTotalCosts.toString().length > 3
                      ? 12
                      : 16
                  }px`,
                }}
              >
                ${playerTotalCosts || 0}
              </b>
            </div>
            <i>(Total costs)</i>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ProgressCard;
