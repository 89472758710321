import {
    XYPlot, LineSeries, VerticalBarSeries, MarkSeries,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalGridLines,
    DiscreteColorLegend,
} from 'react-vis';
import '../../../node_modules/react-vis/dist/style.css';
const Comp = () => {
    const data = [
        { x: 0, y: 8},
        { x: 1, y: 5 },
        { x: 2, y: 4 },
        { x: 3, y: 9 },
        { x: 4, y: 1 },
        { x: 5, y: 7 },
        { x: 6, y: 6 },
        { x: 7, y: 3 },
        { x: 8, y: 2 },
        { x: 9, y: 0 }
    ];
    return (
        <>
            <XYPlot height={200} width={200} color="#cd3b54" stroke="blue">
                <VerticalBarSeries data={data} />
            </XYPlot>
            <XYPlot height={200} width={200}>
                <LineSeries data={data} />
            </XYPlot>
            <XYPlot height={200} width={200}>
                <MarkSeries data={data} />
            </XYPlot>
            <XYPlot xType="linear" width={300} height={300}>
                <DiscreteColorLegend
                    style={{ position: 'absolute', left: '200px', top: '-50px' }}
                    orientation="horizontal"
                    items={[
                        {
                            title: 'Apples',
                            color: '#12939A'
                        },
                        {
                            title: 'Oranges',
                            color: '#79C7E3'
                        }
                    ]}
                />
                <HorizontalGridLines />
                <VerticalGridLines />
                <XAxis title="X Axis" />
                <YAxis title="Y Axis" />
                <LineSeries
                    data={[{ x: 1, y: 3 }, { x: 2, y: 5 }, { x: 3, y: 15 }, { x: 4, y: 12 }]}
                />
                <LineSeries data={null} />
                <LineSeries
                    data={[{ x: 1, y: 10 }, { x: 2, y: 4 }, { x: 4, y: 2 }, { x: 5, y: 15 }]}
                />
            </XYPlot>

        </>
    )
}; export default Comp;