
/* import ChartEx from '../components/charts/LineChart1' */
import  ChartEx from '../components/charts/ReactVisDemo'
const Comp = () => {

    return <><div>Stats1</div>
    <ChartEx/>

    </>;
  };
  export default Comp;