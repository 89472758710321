import React, { useState } from 'react';
import { Button, CircularProgress, Typography, Box, Container, TextField, Card, CardContent, CardMedia, Grid } from '@mui/material';
import bgPic from "../assets/grain_field.webp";
import { enqueueSnackbar } from "notistack";

const Checkout = () => {
  const [loading, setLoading] = useState(false);
  const [nbrOfPlayers, setNbrOfPlayers] = useState(4);

  async function handleCheckout() {
    setLoading(true);

    try {
      const response = await fetch('https://beergameapi-b7cb49ccfea5.herokuapp.com/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nbrOfPlayers: nbrOfPlayers }),
      });

      if (response.status === 200) {
        const { url } = await response.json();
        window.location.href = url;
      } else if (response.status === 422) {
        const errorData = await response.json();

        enqueueSnackbar(`Error: ${errorData.error.nbrOfPlayers}`, {
          variant: "error",
          autoHideDuration: 4000,
          style: { cursor: "pointer" },
          SnackbarProps: {
            onClick: () => {
              closeSnackbar();
            },
          },
        });
      } else {
        enqueueSnackbar("Something went wrong. Please try again.", {
          variant: "error",
          autoHideDuration: 4000,
          style: { cursor: "pointer" },
          SnackbarProps: {
            onClick: () => {
              closeSnackbar();
            },
          },
        });
      }
    } catch (error) {
      enqueueSnackbar("An error occurred during the checkout process. Please try again.", {
        variant: "error",
        autoHideDuration: 4000,
        style: { cursor: "pointer" },
        SnackbarProps: {
          onClick: () => {
            closeSnackbar();
          },
        },
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box sx={{
      backgroundColor: '#fefefe',
      minHeight: '100vh',
      display: 'flex',
      py: 4,
    }}>
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <Card variant="outlined" sx={{ width: '100%' }}>
                <CardMedia
                  component="img"
                  alt="Beergame"
                  height="140"
                  image={bgPic}
                />
                <CardContent>
                  <Typography variant="h5" component="div">
                    Beer Game
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Customize the quantity and proceed to checkout.
                  </Typography>
                  <TextField
                    label="Number of Players"
                    value={nbrOfPlayers}
                    onChange={(e) => setNbrOfPlayers(Number(e.target.value))}
                    variant="outlined"
                    type="number"
                    fullWidth
                    sx={{ mt: 2 }}
                    inputProps={{
                      min: 1,
                    }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">Price: 20 × {nbrOfPlayers} = {nbrOfPlayers * 20}</Typography>
                    {nbrOfPlayers < 5 && (
                      <Typography variant="body1">Administration fee: 25</Typography>
                    )}
                    <Typography variant="h6" component="div">Total: {nbrOfPlayers < 5 ? nbrOfPlayers * 20 + 25 : nbrOfPlayers * 20} USD</Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCheckout}
                    disabled={loading}
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Proceed to Checkout'}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Checkout;