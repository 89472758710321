export const joinGameRequest = async (role, gameId, playerName, gameName) => {
    localStorage.setItem("gameName", gameName);
    const url = `https://beergameapi-b7cb49ccfea5.herokuapp.com/join`

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                role: role,
                gameId: gameId,
                playerName: playerName,
            }),
        });

        const responseJson = await response.json();

        if (response.status === 200 || response.status === 201){
            
            // sätter userId
            localStorage.setItem("userId", responseJson.game.ActingPlayer.ID);
            localStorage.setItem("gameId", responseJson.game.Game.ID);
            localStorage.setItem("role", role);
            
            return {   
                success: true,
                statusCode: response.status
            };
        } else {
            return {
                success: false,
                statusCode: responseJson.status,
                error: JSON.stringify(responseJson.error),
            }
        }
        

    } catch (error) {
        console.error("an error occurred", error);
        return {
            success: false,
            statusCode: null,
            error: error.message
        };
    }
}