export const createGameRequest = async (role, gameName, weeks, playerName, ghostPlayers) => {

    const url = `https://beergameapi-b7cb49ccfea5.herokuapp.com/new`;

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                role: role,
                name: gameName,
                rounds: weeks,
                playerName: playerName,
                ghosts: ghostPlayers
            }),
        });

        const responseJson = await response.json();
        

        if (response.status === 200 || response.status === 201){
            
            // sätter userId
            localStorage.setItem("userId", responseJson.game.ActingPlayer.ID);
            localStorage.setItem("gameId", responseJson.game.Game.ID);
            localStorage.setItem("gameName", responseJson.game.Game.Name);
            localStorage.setItem("role", role);

            return {   
                success: true,
                statusCode: response.status
            };
        } else {
            return {
                success: false,
                statusCode: responseJson.status,
                error: JSON.stringify(responseJson.error),
            }
        }
        

    } catch (error) {
        console.error("an error occurred", error);
        return {
            success: false,
            statusCode: null,
            error: error.message
        };
    }
}