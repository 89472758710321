import React from 'react'
import { motion } from 'framer-motion';

const ShakeComponent = ({children, trigger}) => {

    const shakeAnimation = {
        x: trigger ? [0, -1, 5, -1, 5, 0] : 0, // Rör komponenten horisontellt
        transition: { type: 'tween', duration: 0.25, repeat: 2 } // Upprepa animeringen oändligt
      };
  return (
    <motion.div 
      animate={shakeAnimation}
      className='shakyShaky'>
        {children}
    </motion.div>
  )
}

export default ShakeComponent