
export const playGameRequest = async (userId, round, placedOrder, gameId) => {
    const url = `https://beergameapi-b7cb49ccfea5.herokuapp.com/play`


    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userId: userId,
                round: round,
                play: placedOrder,
                gameId: gameId,
            }),
        });
            
        const responseJson = await response.json();
        
        if (response.status === 200 || response.status === 201) {
    
            return {
                success: true,
                response: responseJson,
                statusCode: response.status,
            };
        } else if (response.status !== 409) {
    
            return {
                success: false,
                statusCode: response.status,
                error: JSON.stringify(responseJson.error)
            };
        } else {
            return playGameRequest(userId, round, placedOrder, gameId);
        }
    } catch (error) {
        
        console.error("an error occurred", error)
        return {
            success: false,
            statusCode: null,
            error: error.message
        };
    }
};