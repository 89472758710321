export const getAvailableGamesRequest = async () => {
    
    const url = `https://beergameapi-b7cb49ccfea5.herokuapp.com/list`;
    
    try {

        const response = await fetch(url, {
            method: "GET",
                headers: {
                "Content-Type": "application/json",
            },
        });

        const responseJson = await response.json();

        if (response.status === 200 || response.status === 201) {
            return responseJson.games;
        } else {

            const errorResponseGame = {
                ID: 'error',
                Name: `Error: ${response.status}`
            };
            return [errorResponseGame];
        }
    } catch (error) {

        console.log(error.message)

        const errorGame = {
            ID: 'error',
            Name: error.message
        };
        return [errorGame];
    }
};