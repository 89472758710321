import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

const TextAnimate = ({ children, direction }) => {

  const controls = useAnimation();
  const ref = useRef(null);

  // Omvandlar direction till x och y koordinater
  const getDirection = (direction) => {
    switch (direction) {
      case 'left': return { x: -72, y: 0 };
      case 'right': return { x: 72, y: 0 };
      case 'up': return { x: 0, y: -72 };
      case 'down': return { x: 0, y: 72 };
      default: return { x: 0, y: 0 };
    }
  }

  // Använd resultatet från getDirection baserat på den angivna riktningen
  const { x, y } = getDirection(direction);

  const isTriggered = sessionStorage.getItem("animationCanTrigger") === "true";

  useEffect(() => {
    let isMounted = true; // Flagga för att kontrollera om komponenten är monterad

    if (isTriggered) {
      // Initialt tillstånd utan delay och duration
      controls.start({ opacity: 0, x, y, transition: { duration: 0, delay: 0 } })
        .then(() => {
          if (isMounted) {
            // Slut tillstånd med anpassad duration och delay
            controls.start({ opacity: 1, x: 0, y: 0, transition: { duration: 0.75, delay: 0.3 } });
          }
        });
    }

    return () => {
      isMounted = false; // Komponenten avmonteras, uppdatera flaggan
    };
  }, [isTriggered, controls, x, y]); // Lägg till x och y i beroendelistan för att reagera på riktningens ändring

  return (
    <div ref={ref} style={{ position: "relative", width: "fit-content", overflow: "hidden" }}>
      <motion.div
        viewport={{ once: false }}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, x, y },
          visible: { opacity: 1, x: 0, y: 0 }
        }}
        transition={{ duration: 0.75, delay: 0.2 }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default TextAnimate;
