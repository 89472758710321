import { Navigate, useRoutes } from 'react-router-dom';
import BaseLayout from './layouts/BaseLayout';
import Game from './pages/Game';
import Stats1 from './pages/Stats1';
import CreateJoinGame from './pages/CreateJoinGame';
import { GameOver } from './pages/GameOver';
import NotFound from './pages/NotFound';
import Billing from './pages/Billing';
import Checkout from './pages/Checkout';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentCanceled from './pages/PaymentCancled';
import SelectPaymentMethod from './pages/SelectPaymentMethod';

const ProtectedRoute = ({ children }) => {
  
  let gameAccess = localStorage.getItem('gameAccess') === "true";

  return gameAccess ? children : <Navigate to ="/" replace />;
  
};

const GameOverCheck = ({ children }) => {

  let gameOver = localStorage.getItem('gameOver') === "true";

  return gameOver ? children : <Navigate to ="/" replace />;
}

const Router = () => {
  return useRoutes([
    {
      path: '/play',
      element: <BaseLayout />,
      children: [
        { path: '/play', element: <ProtectedRoute><Game /></ProtectedRoute> },
        { path: 'stats', element: <ProtectedRoute><Stats1 /></ProtectedRoute> },
        { path: 'gameOver', element: <ProtectedRoute><GameOverCheck><GameOver /></GameOverCheck></ProtectedRoute>}
      ],
    },
    {
      path: '/',
      element: <BaseLayout />,
      children: [
        { path: '/', element: <CreateJoinGame /> },
      ]
    },
    { path: "billing", element: <Billing /> },
    { path: "checkout", element: <Checkout /> },
    { path: "payment-method", element: <SelectPaymentMethod /> },
    { path: "payment-success", element: <PaymentSuccess /> },
    { path: "payment-canceled", element: <PaymentCanceled /> },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: '404', element: <NotFound /> },
  ]);
};
export default Router;
