import React from 'react';
import { Card, Grid, Box, IconButton, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useNavigate } from 'react-router-dom';
import maLogo from "../assets/ma_logo.webp";
import Footer from './Footer';

const SelectPaymentMethod = () => {
  const navigate = useNavigate();

  const handleCardPayment = () => {
    navigate('/checkout');
  };

  const handleInvoicePayment = () => {
    navigate('/billing');
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>

      <Box sx={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ margin: '0 auto', maxWidth: "35em", marginTop: "2em", marginBottom: "2em" }}>
          <Card style={{ padding: "1em" }} variant="outlined">
            <Grid container>
              <Grid item xs={9}>
                <h1>Select payment method</h1>
              </Grid>
              <Grid item xs={3}>
                <img src={maLogo} alt="My Logo" style={{ width: '80px', height: 'auto' }} />
              </Grid>
            </Grid>

            <p>For commercial organizations we charge USD $20 per game participant. For invoices with a sum less than $100, an extra administrative fee of $25 is charged.</p>
            <p>Thank you for being honest and helping us cover our costs for running the Beer game!</p>

            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12} md={6}>
                <Card
                  variant="outlined"
                  sx={{ display: 'flex', alignItems: 'center', padding: 2, cursor: 'pointer' }}
                  onClick={handleCardPayment}
                >
                  <IconButton>
                    <CreditCardIcon fontSize="large" />
                  </IconButton>
                  <Typography variant="h6" sx={{ ml: 2 }}>
                    Pay with Card
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card
                  variant="outlined"
                  sx={{ display: 'flex', alignItems: 'center', padding: 2, cursor: 'pointer' }}
                  onClick={handleInvoicePayment}
                >
                  <IconButton>
                    <ReceiptLongIcon fontSize="large" />
                  </IconButton>
                  <Typography variant="h6" sx={{ ml: 2 }}>
                    Pay with Invoice
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </div>
      </Box>
     <Footer />
    </Box>
  );
};

export default SelectPaymentMethod;
