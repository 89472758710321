import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
//import { HelmetProvider } from 'react-helmet-async';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import './App.css';
import './styles/cardsectionGrid.css'
import App from './App';
import theme from './theme';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
     <ThemeProvider theme={theme}>
     <CssBaseline />
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </ThemeProvider>
);
